
export class Constants {

  constructor(
  ) {
  }
  private static readonly _acceptedFilesTypes = ['.pdf', '.doc', '.docx', '.jpeg', '.png', '.heic','.jpg'];
  static AVOID_INTERCEPTOR_HEADER = 'X-Skip-Interceptor-Error-Handler';
  static CONTENT_TYPE_HEADER = 'Content-Type';

  static ACCESS_TOKEN = 'accessToken';
  static EMAIL = 'email';
  /* routes  names */
  static LOGIN_ROUTE = 'sign-in';
  static LOGOUT_ROUTE = 'sign-out';
  static REGISTER_ROUTE = 'sign-up';
  static FORGOT_ROUTE = 'forgot-password';
  static RESET_PASSWORD_ROUTE = 'reset-password';
  static SEARCH_ROUTE = 'search';
  static HOME_ROUTE = 'home';
  static ANNOUNCEMENTS_ROUTE = 'jobs';
  static PREVIEW_ANNOUNCEMENT_ROUTE = 'preview-job';
  static REDIRECTION_ROUTE = 'redirection';
  static REDIRECTION_AUTH_ROUTE = 'redirection-auth';
  static ACCOUNT_ACTIVATION_ROUTE = 'account-activation';
  static PROFILE_ROUTE = 'profile';
  static ALERTS_ROUTE = 'alerts';
  static ABOUT_US_ROUTE = 'about-us';
  static CONTACT_US_ROUTE = 'contact-us';
  static PRIVACY_POLICY_ROUTE = 'privacy-policy';
  static POLITICS_ROUTE = 'politics';
  static NOT_FOUND_ROUTE = 'not-found';
  static CANDIDATE_ROUTE = 'candidate';
  static CANDIDACIES_ROUTE = 'candidacies';
  static POLITICS_VALIDATION_ROUTE = 'politics-validation';
  static ACCOUNT_FINALIZATION_ROUTE = 'account-finalization';
  static CANDIDATE_PARSING_RESUME_ROUTE = 'parsing-cv';
  static CANDIDATE_MANUAL_REGISTER_ROUTE = 'manual-registration';
  static REGISTER_DECIDER_ROUTE = 'register-decider';
  static CANDIDATE_INFORMATIONS_ROUTE = 'candidate-informations';
  static CREATE_ALERT_ROUTE = 'create-alert';
  static EDIT_ALERT_ROUTE = 'edit-alert';
  static QUICK_APPLY = 'quick-apply';
  static CREATE_JOB_OFFER_ROUTE = 'create-job-offer';
  static TRAINING_ROUTE = 'training';
  static RESUME_HELP_AND_ADVICE_ROUTE = 'resume-help-and-advice';
  static SWITZERLAND_WORKING_GUIDE_ROUTE = 'switzerland-working-guide';
  static INSURANCE_ADVISOR_TRAINING_ROUTE = 'insurance-advisor-training';
  static OFFICE_TRAINING_WEBMARKETING_ROUTE = 'office-training-webmarketing';
  static TRADING_TRAINING_CENTER_ROUTE = 'traiding-training-center';



  /** helpers */
  static DATE_FORMAT_FOR_BACK_SENDER = 'yyyy-MM-dd';
  static MY_DATE_FORMAT = {
    parse: {
      dateInput: 'DD-MM-YYYY', // this is how your date will be parsed from Input
    },
    display: {
      dateInput: 'DD-MM-YYYY', // this is how your date will get displayed on the Input
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    }
  };



  /** http headers names */
  static RESPONSE_HEADER_TOTAL_ITEMS = 'X-Total-Count';

  /* filters names */
  static PAGE_ENTITY = 'page';
  static TITLE_FILTER = 'title';
  static LOCATION_FILTER = 'location';
  static CONTRACTS_FILTER = 'contracts';
  static COMPANIES_FILTER = 'companies';
  static POSTED_DATE_FILTER = 'posted-date';
  static START_END_DATE_FILTER = 'start-end-date';
  static START_DATE_FILTER = 'startDate';
  static END_DATE_FILTER = 'endDate';
  static ACTIVITY_AREA_FILTER = 'sectors';
  static GEOGRAPHICAL_MOBILITY_FILTER = 'geographicMobilities';
  static MAX_ACTIVITY_RATE_FILTER = 'maxActivityRate';
  static MIN_ACTIVITY_RATE_FILTER = 'minActivityRate';
  static PULISHED_DATE_FILTER = 'publishedDate';


  /* snackbar messages */
  static SNACKBAR_SUCCESS = 'success-message-snackbar';
  static SNACKBAR_ERROR = 'error-snackbar';
  static SNACKBAR_INFO = 'info-message-snackbar';


  static USER_INFO = 'user-info';
  /* actions */
  static LOGIN_ACTION = '[Login] Login';
  static LOGIN_LOADING_ACTION = '[Login] Login loading';
  static LOGIN_SUCCESS_ACTION = '[Login] Login Success';
  static LOGIN_FAILURE_ACTION = '[Login] Login Failure';
  static LOGOUT_ACTION = '[Logout] Logout';
  static CHANGE_PASSWORD_ACTION = '[profile] change password';
  static REGISTER_CREDENTIALS_ACTIONS = '[sign-up] register credentials';
  static METHOD_CHOSEN_ACTIONS = '[sign-up] method chosen';
  static USER_INFOS_ACTIONS = '[sign-up] user infos action';
  static REGISTER_LOADING_ACTION = '[sign-up] sign-up loading';
  static REGISTER_FAILURE_ACTION = '[sign-up] sign-up Failure';
  static SET_IS_FIRST_LOGIN_ACTION = '[account-finalization] set first login false action'
  static SET_FILTERS_VALUES = '[search-filters] set filters values';
  static SET_SEARCH_VALUES = '[search-filters] set search values';
  static SET_PAGE_VALUES = '[search-filters] set page values';
  static TITLE_FILTER_CHANGED = '[search-filters] title filter values';
  static LOCATION_FILTER_CHANGED = '[search-filters] location filter values';
  static CONTRACTS_FILTER_CHANGED_ACTION = '[search-filters] contracts filter values';
  static COMPANIES_FILTER_CHANGED_ACTION = '[search-filters] companies filter values';
  static POSTED_DATE_FILTER_CHANGED_ACTION = '[search-filters] posted date filter values';
  static NOTIFY_CHILDREN_ACTION = '[search-filters] notify children';
  static ACTIVITY_AREA_FILTER_CHANGED = '[search-filters] activity area filter values';
  static GEOGRAPHICAL_MOBILITY_FILTER_CHANGED = '[search-filters] geographical mobility filter values';
  static MAX_ACTIVITY_RATE_FILTER_ACTION = '[search-filters] max activity rate filter values';
  static MIN_ACTIVITY_RATE_FILTER_ACTION = '[search-filters] min activity rate filter values';
  static PUBLISHED_DATE_FILTER_ACTION = '[search-filters] published date filter values';
  /* method to encode query params */
  static encode({ valueToEncode }: { valueToEncode: any }): string {
    return btoa(valueToEncode);
  }
  /* method to decode query params */
  static decode({ valueToDecode }: { valueToDecode: string }): any {
    return atob(valueToDecode);
  }

  /* encode query params */
  static encodeQueryParams({ queryParams }: { queryParams: Map<string, any> }): string {
    const keys = queryParams.keys();
    const encodedParams = Array.from(keys).map((key) =>
      `${encodeURIComponent(key)}=${this.encode({ valueToEncode: queryParams.get(key) })}`);
    return encodedParams.join('&');;
  }

    /** breakpoints */
    static mobileAndTabletBreakpoint = '(min-width: 200px) and (max-width: 699px)'
    static xSmallToSmallBreakpoint = '(min-width: 200px) and (max-width: 499px)'
    static smallToMediumBreakpoint = '(min-width: 500px) and (max-width: 699px)';
    static mediumToLargeBreakPoint = '(min-width: 700px) and (max-width: 1023px)';
    static largeToXlargeBreakPoint = '(min-width: 1024px) and (max-width: 1199px)';
    static tabletLaptopScreens = '(min-width: 700px)';
    static largeBreakPoint = '(min-width: 1024px)';
    static xLargeBreakPoint = '(min-width: 1200px)';

    /** others */
    static candidate = 'CANDIDATE';
    static recruiter = 'CLIENT';
    static maxNumberOfIASkilsGenetation = 3;

  /** get accepted types */
  static get  acceptedFilesTypes(): string[] {
    return this._acceptedFilesTypes;
  }
}
